<ng-template #defaultTpl>

</ng-template>

<ng-template #livechatIncTpl>
  <button mat-icon-button type="button" (click)="trigger()">
    <ng-content select=".chat-text"></ng-content>
  </button>
  <livechat-widget [license]="liveChatInc.toString()" [customerName]="me?.username" [visibility]="widgetState$.value?.visibility"></livechat-widget>
</ng-template>

@switch (chat) {
  @case ('livechatInc') {
    <ng-container [ngTemplateOutlet]="livechatIncTpl"></ng-container>
  }
  @default {
    <ng-container [ngTemplateOutlet]="defaultTpl"></ng-container>
  }
}
